import "core-js/modules/es6.regexp.to-string";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import TimeScreeningse from '@/components/timeScreeningse';
import Head from '@/components/head/index';
import { ApiLog } from '@/api/updateUserInfo/Journal';
export default {
  data: function data() {
    return _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({
      tab: 0,
      value1: null,
      title: '历史登陆',
      prohibit: false,
      page: 1,
      pagesize: 15,
      course: this.$route.query.course
    }, "prohibit", false), "tutorialEs", false), "total", 0), "tableData", []), "username", ''), "start_time", ''), "end_time", ''), "make_type", ''), "optionsvalue", ''), "options", []);
  },
  components: {
    Head: Head,
    TimeScreeningse: TimeScreeningse
  },
  created: function created() {
    if (this.course != 1) {
      this.GetList();
    } else {}
  },
  methods: {
    //时间
    screening: function screening(item) {
      var _this = this;
      this.start_time = item[0];
      this.end_time = item[1];
      this.$nextTick(function () {
        _this.GetList();
      });
    },
    //分页
    handleCurrentChange: function handleCurrentChange(val) {
      var _this2 = this;
      this.page = val;
      this.$nextTick(function () {
        _this2.GetList();
      });
    },
    handleSizeChange: function handleSizeChange(val) {
      var _this3 = this;
      this.pagesize = val;
      this.$nextTick(function () {
        _this3.GetList();
      });
    },
    GetList: function GetList() {
      var _this4 = this;
      ApiLog({
        page: this.page,
        pagesize: this.pagesize,
        username: this.$store.state.user.username,
        start_time: this.start_time,
        end_time: this.end_time,
        make_type: '1'
      }).then(function (respomse) {
        _this4.tableData = respomse.data.data;
        _this4.total = respomse.data.total;
      });
    },
    //时间戳查询
    DataTime: function DataTime() {
      var _this5 = this;
      if (this.value1 == null) {
        this.$nextTick(function () {
          _this5.GetList();
        });
      } else {
        var s_time = this.value1[0].toString();
        this.start_time = s_time.substring(0, 10);
        var e_time = this.value1[1].toString();
        this.end_time = e_time.substring(0, 10);
        this.$nextTick(function () {
          _this5.GetList();
        });
      }
    },
    getTimestamp: function getTimestamp(time) {
      //把时间日期转成时间戳
      return new Date(time).getTime() / 1000;
    }
  }
};